* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Roboto", sans-serif;
  background-color: #f5f5f5;
}

.files-upload {
  max-width: 600px;
  margin: 0 auto;
}

.nav-link {
  position: relative;
  text-decoration: none;
  margin: 0 20px;
  color: brown !important;
  text-transform: uppercase;
}

.list-unstyled a {
  position: relative;
  text-decoration: none;
  color: brown !important;
  text-transform: uppercase;
}

.nav-link::after,
.list-unstyled a::after {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #000;
  transition: width 0.3s;
}

footer {
  position: relative;
  margin-top: 7rem;
}

footer::before {
  content: "";
  display: block;
  height: 0.5px; /* Thickness of the line */
  background-color: brown; /* Color of the line */
  width: 72%; /* Adjust the width as needed */
  position: absolute;
  top: 0; /* Position at the top of the footer */
  left: 50%;
  transform: translateX(-50%);
}

footer img {
  max-width: 30%;
  height: auto;
}

.navbar::after {
  content: "";
  display: block;
  height: 0.3px; /* Thickness of the underline */
  background-color: brown; /* Color of the underline */
  width: 72%; /* Adjust the width as needed */
  position: absolute;
  bottom: -5px; /* Distance from the bottom of the element */
  left: 50%;
  transform: translateX(-50%);
}

.nav-link:hover::after,
.list-unstyled a:hover::after {
  width: 100%;
}

.last-nav .nav-link:last-child,
.nav-link:first-child {
  color: darkgreen !important;
}

.carousel-item {
  opacity: 0;
  transition: opacity 1s !important;
}
.carousel-item.active {
  opacity: 1;
  transition: opacity 1s !important;
}

.modal-body {
  padding: 0 !important;
  overflow: hidden !important;
}

.Home,
.Home-left-box {
  margin-top: 5rem;
}

/* folder icon */

.folder-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.folder {
  cursor: pointer;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  gap: 5px;
}

.folder.selected {
  background-color: #eef;
  border-color: #aaf;
}

.folder-icon {
  /* Adjust the icon style as needed */
}

.folder-name {
  /* Adjust the text style as needed */
}

/* folder icons */

.offscreen {
  position: absolute;
  left: -9999px;
}

.hide {
  display: none;
}

.errmsg {
  background-color: lightpink;
  color: firebrick;
  font-weight: bold;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}

[type="checkbox"] {
  height: 20px;
  width: 20px;
  margin: 5px 5px 2px 2px;
}

@media screen and (max-width: 600px) {
  .login_register_container {
    padding: 0 40px;
  }
  .footer_first_col {
    order: 4;
  }
  footer .list-unstyled {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  footer h5 {
    text-align: center;
  }
}

/* Vertical Dashboard */
:root {
  --dark-green: darkgreen;
  --brown: brown;

  --sidebar-width: 100px; /* Smaller width for collapsed state */
  --sidebar-width-expanded: 250px;
  --icon-active-color: var(--dark-green);
  --icon-inactive-color: var(--brown);
  --text-active-color: var(--dark-green);
  --text-inactive-color: var(--brown);
  --button-bg-color: var(--dark-green);
  --button-text-color: white;
  --button-hover-bg-color: var(--brown);
}

.app-layout {
  display: flex;
}

.vertical-dashboard {
  width: var(--sidebar-width);
  height: 100vh;
  top: 0;
  left: 0;
  background-color: #f8f9fa;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  transition: width 0.3s ease;
}

.vertical-dashboard:hover {
  width: var(--sidebar-width-expanded);
}

.vertical-dashboard .nav-link {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.vertical-dashboard .nav-link .icon,
.vertical-dashboard .nav-link .text {
  transition: color 0.3s ease;
}

.vertical-dashboard .nav-link .icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 10px;
  color: var(--icon-inactive-color);
}

.vertical-dashboard .nav-link .text {
  color: var(--text-inactive-color);
}

.vertical-dashboard .nav-link.active .icon {
  color: var(--icon-active-color);
}

.vertical-dashboard .nav-link.active .text {
  color: var(--text-active-color);
}

.vertical-dashboard .nav-link .text {
  display: none;
}

.vertical-dashboard:hover .nav-link .text {
  display: inline;
}

.vertical-dashboard:hover .nav-link .icon {
  margin-right: 10px;
}

.content {
  margin-left: var(--sidebar-width);
  padding: 20px;
  width: calc(100% - var(--sidebar-width));
  transition: margin-left 0.3s ease, width 0.3s ease;
}

.vertical-dashboard:hover ~ .content {
  margin-left: var(--sidebar-width-expanded);
  width: calc(100% - var(--sidebar-width-expanded));
}

@media (max-width: 768px) {
  .vertical-dashboard {
    /* Turn off styling for small screens */
    all: unset;
    margin-bottom: 15px;
  }
  .app-layout {
    flex-direction: column;
    align-items: center;
  }
  .profileImg {
    text-align: center;
  }
}

/* custom buttons */

.btn-custom {
  background-color: var(--button-bg-color) !important;
  color: var(--button-text-color) !important;
}

.btn-custom:hover {
  background-color: var(--button-hover-bg-color) !important;
  color: var(--button-text-color) !important;
}

.form-control-custom {
  border-color: var(--brown);
}

.form-control-custom:focus {
  border-color: var(--dark-green);
  box-shadow: 0 0 0 0.2rem rgba(0, 100, 0, 0.25);
}

/* Form Labels */
.heading {
  color: var(--dark-green) !important;
}
.form-label {
  color: var(--dark-green);
  font-weight: bold;
  margin-top: 1rem;
}

/* Cards */
.card {
  transition: all 0.3s;
}

.card:hover {
  transform: scale(1.15);
}
